import React from "react";

const Counter = ({
  counterSteps,
  smallText,
  isDecimal,
  elementId,
  isHundreds,
}) => {
  const [startCounter, setStartCounter] = React.useState(false);
  const elementRef = React.useRef(null);
  const handleScroll = React.useCallback(() => {
    if (typeof window !== "undefined") {
      if (elementRef.current.offsetTop > 0) {
        setStartCounter(true);
      }
    }
  }, []);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      function watchScroll() {
        window.addEventListener("scroll", handleScroll);
      }
      watchScroll();
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <div
      className="w-100 d-flex justify-content-center"
      style={{ height: "147px" }}
    >
      {elementId === "volume" && <span>Über </span>}
      <div data-sal="slide-up">
        <div
          id={elementId}
          style={{ "--nsp-counter-steps": counterSteps, margin: "0 0 60px 0" }}
          ref={elementRef}
        >
          {startCounter && (
            <div className="text-center">
              <div className="counter">
                <div className="numbers">
                  {Array.from(Array(counterSteps).keys()).map((n, i) => (
                    <div key={i}>
                      {isDecimal
                        ? (n / 10).toFixed(1).toString().replace(".", ",")
                        : n}
                      {isHundreds && "00"}
                    </div>
                  ))}
                </div>
              </div>
              <p>{smallText}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Counter;
