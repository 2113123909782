import { ABOUT, ABOUT_TITLE } from "../components/core/nav/NavigationItems";
import { Col, Container, Row } from "react-bootstrap";

import Counter from "../components/core/ux/Counter";
import HomeShape from "../components/core/ux/HomeShape";
import Layout from "../components/core/Layout";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import TeamCarousel from "../components/core/ux/TeamCarousel";

const AboutPage = () => (
  <Layout page={ABOUT}>
    <HomeShape />
    <Container>
      <h2 className="text-uppercase h-muted">{ABOUT}</h2>
      <h1 className="text-uppercase h bolder">{ABOUT_TITLE}</h1>
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">
          <strong>news.space</strong> Projektentwicklung
          <div className="h-muted">
            Verständnis. Vertrauen. Verbindlichkeit.
          </div>
        </h3>
      </div>
      <Row>
        <Col></Col>
        <Col lg={5}>
          <div className="over">
            <Counter
              counterSteps={401}
              smallText="Mio. € Investitionsvolumen"
              isDecimal={false}
              elementId="volume"
            />
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={5} className="vertical-middle">
          <Counter
            counterSteps={1500 / 100 + 1}
            smallText="Wohnungen"
            elementId="flats"
            isHundreds={true}
          />
        </Col>
        <Col xs={12} lg={7} className="vertical-middle">
          {/** scroll effect broken on mobile (content won't show) */}
          <p lang="de" style={{ hyphens: "auto" }}>
            <strong>new.space</strong> ist ein inhabergeführter
            Projektentwickler aus Kassel mit Immobilienprojekten im gesamten
            Bundesgebiet. Wir planen vorwiegend Konzepte für Senior Living. Und
            falls der Bedarf im Quartier weitere Wohnkonzepte erfordert,
            realisieren wir gerne auch Lösungen für Student-Living bis hin zu
            Wohn- und Lebenskonzepten für Mehrgenerationen-Immobilien. Höchste
            Flexibilität, persönliche Freiheit sowie maximale Selbstbestimmung
            sind Ziele, die wir mit Erfolg umsetzen. Unsere Konzepte orientieren
            sich immer an den individuellen Bedürfnissen der Bewohner und
            Nutzer.
          </p>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <p lang="de" style={{ hyphens: "auto" }}>
              <strong>new.space</strong> Living-Konzepte sind stets nachhaltig –
              und bilden damit das Fundament für werthaltige Investments. Bei
              unseren Planungen und Umsetzungen legen wir mit Blick auf die
              Zukunft immer nachdrücklicher Wert auf den Schutz von Ressourcen,
              auf ESG-Anlagekriterien (Environment, Social, Governance) sowie
              auf DGNB-Zertifizierungen. Zudem fördern wir die Umsetzung einer
              Circular Economy. new.space Wohn- und Lebenskonzepte ermöglichen
              ein Wohlbefinden über viele Jahre, hohen Komfort und viele
              Begegnungsorte für Menschen.
            </p>
          </div>
        </Col>
        <Col xs={12} lg={5} className="vertical-middle">
          <Counter
            counterSteps={301}
            smallText="Studentenwohnungen"
            elementId="student-flats"
          />
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={5} className="vertical-middle">
          <Counter counterSteps={21} smallText="Pflegeheime" />
        </Col>
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <p lang="de" style={{ hyphens: "auto" }}>
              <strong>new.space</strong> deckt mit rund 50 erfahrenen
              Mitarbeiter*innen alle Disziplinen inhouse ab – von der
              Standortanalyse und dem Grundstücksankauf, der Architektenplanung
              und Bauüberwachung bis hin zu Vermietung oder Verkauf. Alles aus
              einer Hand. Alles zuverlässig und reibungslos. Dazu haben wir die
              Abläufe optimiert und unseren Blick für die Details geschärft.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <p lang="de" style={{ hyphens: "auto" }}>
              <strong>new.space</strong> weiß, worauf es ankommt – und versteht
              die Anforderungen unserer Zeit. Darum planen wir den gesamten
              Lebenszyklus im Voraus. Dadurch sichern wir die Wertschöpfung der
              Immobilien und ermöglichen stimmige Lebenskonzepte. Aufgrund
              unserer Erfahrung, unserer langjährigen Partner sowie der
              Kompetenz unseres Teams können wir verbindliche Aussagen treffen.
              Diese schaffen das Vertrauen, um gemeinsam Projekte erfolgreich
              und mit Begeisterung zu realisieren.
            </p>
          </div>
        </Col>
        <Col xs={12} lg={5} className="vertical-middle">
          <Counter
            counterSteps={201}
            smallText="Wohneinheiten für betreutes Wohnen"
            elementId="care-flats"
          />
        </Col>
      </Row>
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">
          <strong>new.space</strong> Unsere Vision. Unsere Verantwortung.
        </h3>
      </div>
      <Row className="my-5">
        <Col xs={12} lg={5} className="d-flex justify-content-end">
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/final/OFFICE-2.jpg"}
              alt="About new.space"
              placeholder="blurred"
              className="grow"
              quality={100}
            />
          </div>
        </Col>
        <Col xs={12} lg={7}>
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                Immer den Menschen im Blick
              </div>
              <div className="h"></div>
            </div>
            <p lang="de" style={{ hyphens: "auto" }}>
              Als Projektentwickler tragen wir eine große Verantwortung. Für die
              Menschen. Für die Gesellschaft. Darum entwickeln wir besondere
              Gebäude, die den Bewohnern Sicherheit und Orientierung bieten. Mit
              lebenswerten Räumen, in denen man sich geborgen fühlt und gerne
              die Gemeinschaft mit anderen genießt. Bei der Planung und
              Umsetzung achten wir auf nachhaltige Wohnkonzepte, die im Einklang
              mit der Umwelt stehen. Und die in ihrer Ästhetik rundum
              begeistern.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={5} className="d-flex justify-content-end">
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/final/OFFICE-3.jpg"}
              alt="About new.space"
              placeholder="blurred"
              className="grow"
              quality={100}
            />
          </div>
        </Col>
        <Col xs={12} lg={7}>
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                Nachhaltig für eine bessere Zukunft
              </div>
              <div className="h"></div>
            </div>
            <p lang="de" style={{ hyphens: "auto" }}>
              Jedes Projekt von <strong>new.space</strong> hat ökonomische, aber
              auch ökologische und soziale Ziele. Dabei orientieren wir uns an
              den ESG-Kriterien (Environment, Social, Governance). Durch
              nachhaltiges Bauen leisten wir einen wichtigen Beitrag zum Klima-
              und Umweltschutz. Wenn möglich, verarbeiten wir nachhaltige
              Baumaterialien und realisieren die Möglichkeiten der Circular
              Economy. Effiziente Prozesse entlang der Wertschöpfungskette der
              Immobilien sparen zusätzlich wertvolle Ressourcen.
            </p>
          </div>
        </Col>
        <p>
          <br />
          Dass <strong>new.space</strong> Projekte durch dieses Engagement
          zukunftsfähig und nachhaltig sind, bestätigen unter anderem die
          Zertifizierungen der DGNB (Deutsche Gesellschaft für nachhaltiges
          Bauen).
        </p>
      </Row>
      <h2 className="h headline">
        <strong>new.space</strong> Team
      </h2>
      <Row className="my-5">
        <TeamCarousel />
        <Col lg={7}>
          <div data-sal="slide-up" data-sal-duration="900">
            <div className="h-muted text-uppercase">
              <strong>Michael Kopeinigg</strong>, Gründer, Vorstand
            </div>
            <div className="h"></div>
          </div>
          <p lang="de" style={{ hyphens: "auto" }}>
            Dem gebürtigen Österreicher liegen Gebäude für Generationen am
            Herzen – von der Kindertagesstätte über die Studentenwohnung bis hin
            zu Senior Living. Sein größter Fokus: Pflege-Immobilien. Mit über
            zehn Jahren Erfahrung als Projektentwickler genießt Michael
            Kopeinigg größtes Vertrauen bei seinen Kunden.
          </p>
          <p lang="de" style={{ hyphens: "auto" }}>
            Als zweimaliger österreichischer Handball-Meister setzt er auch in
            der Projektentwicklung durch <strong>new.space</strong> auf sein
            Team. Vom Architekten und Bauleiter bis zum Vertrieb sind alle an
            Bord. Auch bei seinen Partnern legt er Wert auf langjährige
            Kontinuität. Seine Zusagen sind für den zweifachen Vater
            verbindlich.
          </p>
        </Col>
        <Col></Col>
      </Row>
      <Row className="my-5">
          
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                <strong>Nikolaus Kühn</strong>, Vorstand
              </div>
              <div className="h"></div>
            </div>
            <p lang="de" style={{ hyphens: "auto" }}>
              Der Dipl.-Ing. Architekt leitet die Ausführungsplanung und stellt
              als Bauleiter sicher, dass alle Projekte zuverlässig ablaufen. Er
              bringt Erfahrungen aus der Realisierung etlicher Großprojekte ein.
              Dabei hat er das große Ganze genauso im Blick wie die kleinen
              Details. Sein Arbeitsplatz ist die Baustelle ebenso wie der
              Schreibtisch.
            </p>
          </div>
        </Col>
        <Col xs={12} lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/portraits/new_space12572-1.jpg"}
              alt="Niko Kühn"
              title="Niko Kühn"
              placeholder="blurred"
              quality={100}
              className="grow"
            />
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                <strong>Nicola Speich-Kopeinigg</strong>, Gesellschafterin
              </div>
              <div className="h"></div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/portraits/new_space12167-1.jpg"}
              alt="Nicola Speich-Kopeinigg"
              title="Nicola Speich-Kopeinigg"
              placeholder="blurred"
              quality={100}
              className="grow"
            />
          </div>
        </Col>
      </Row>
      <hr />
      <h3 className="headline">
        <strong>Aufsichtsrat</strong>
      </h3>
      <Row className="mb-5">
        <Col xs={12} lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/portraits/new_space12830-1.jpg"}
              alt="news.space Aufsichtsrat"
              title="news.space Aufsichtsrat"
              placeholder="blurred"
              quality={100}
              className="grow"
            />
          </div>
        </Col>
        <Col xs={12} lg={7}></Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                <strong>Diethelm Rüthers</strong>
              </div>
              <div className="h"></div>
            </div>
            <p lang="de" style={{ hyphens: "auto" }}>
              Der Aufsichtsratsvorsitzende bringt schwerpunktmäßig seine
              Expertise in den Bereichen Vertrags-, Bau- und Strafrecht ein. Er
              studierte unter anderem Rechtswissenschaft an der Universität
              Münster und absolvierte 1991 sein zweites Staatsexamen.
              Anschließend arbeitete er in namenhaften Kanzleien – zunächst
              angestellt, später als Inhaber.
            </p>
          </div>
        </Col>
        <Col xs={12} lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/portraits/new_space12272-1.jpg"}
              alt="Diethelm Rüthers"
              title="Diethelm Rüthers"
              placeholder="blurred"
              quality={100}
              className="grow"
            />
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                <strong>Rainer Pillmayer</strong>
              </div>
              <div className="h"></div>
            </div>
            <p lang="de" style={{ hyphens: "auto" }}>
              Das Aufsichtsratsmitglied verantwortet als Geschäftsführer 
              sämtliche Aktivitäten im Bereich erneuerbare Energien beim 
              Investment Manager und Projektentwickler GARBE. Zuvor hat er 
              eine Plattform für Immobilien-Crowdfunding mit aufgebaut und 
              hatte weitere Stationen u.a. bei der Strategieberatung BCG, 
              dem Immobilienfinanzierer Eurohypo und als Vorstand der DIC 
              Asset AG.
            </p>
          </div>
        </Col>
        <Col xs={12} lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/portraits/new_space12750-1.jpg"}
              alt="Rainer Pillmayer"
              title="Rainer Pillmayer"
              placeholder="blurred"
              quality={100}
              className="grow"
            />
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                <strong>Matthias Henning</strong>
              </div>
              <div className="h"></div>
            </div>
            <p lang="de" style={{ hyphens: "auto" }}>
              Das Aufsichtsratsmitglied studierte Betriebswirtschaftslehre an
              der Universität Marburg/Kassel und ist heute geschäftsführender
              Gesellschafter der General-Industries Deutschland GmbH, eines
              mittelständischen Kunststoffrecycling-Unternehmens. Zuvor war er
              viele Jahre bei renommierten Strategieberatungen im Bereich
              Reorganisation und Restrukturierung tätig.
            </p>
          </div>
        </Col>
        <Col xs={12} lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/portraits/new_space12787-1.jpg"}
              alt="Matthias Henning"
              title="Matthias Henning"
              placeholder="blurred"
              quality={100}
              className="grow"
            />
          </div>
        </Col>
      </Row>
      <hr />
      <h3 className="headline">
        <strong>Office</strong>
      </h3>
      <Row className="my-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                <strong>Jutta Lämmert-Edenhofner</strong>
              </div>
              <div className="h"></div>
            </div>
            <p lang="de" style={{ hyphens: "auto" }}>
              Die Assistentin der Geschäftsleitung ist Dipl.-Ing. FH Hochbau und
              hat immer ein Auge auf Finanzen und Termine. Zusätzlich
              organisiert sie sämtliche Abläufe im Büro.
            </p>
          </div>
        </Col>
        <Col xs={12} lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/portraits/new_space12485-1.jpg"}
              alt="Jutta Lämmert-Edenhofner"
              title="Jutta Lämmert-Edenhofner"
              placeholder="blurred"
              quality={100}
              className="grow"
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <div data-sal="slide-up" data-sal-duration="900">
              <div className="h-muted text-uppercase">
                <strong>Lukas Hoppe</strong>
              </div>
              <div className="h"></div>
            </div>
            <p lang="de" style={{ hyphens: "auto" }}>
              Der Assistent der Geschäftsführung ist auch im Immobilienvertrieb
              für <strong>new.space</strong> tätig. Er verantwortet das
              Projektmanagement und die Projektsteuerung der Altenpflegeheime.
              Zudem kalkuliert und gewährleistet er die kaufmännische Prüfung
              sämtlicher Projekte.
            </p>
          </div>
        </Col>
        <Col xs={12} lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/portraits/new_space12376-1.jpg"}
              alt="Lukas Hoppe"
              title="Lukas Hoppe"
              placeholder="blurred"
              quality={100}
              className="grow"
            />
          </div>
        </Col>
      </Row>
      {/*
      <RefCarousel />
      <br className="my-5" />
      <h2 className="text-uppercase h-muted">Unsere</h2>
      <h1 className="text-uppercase h bolder">Partner</h1>
      <div className="table-responsive">
        <Table
          className="text-center fs-2 mb-5"
          style={{ marginLeft: "-20px" }}
        >
          <tbody>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="p-5">DGS</td>
              <td className="p-5">P&I</td>
            </tr>
            <tr>
              <td className="p-5">GH GRUNDINVEST</td>
              <td className="p-5">
                <div className="vl"></div>UM- UND AUSBAU
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      */}
    </Container>
  </Layout>
);

export default AboutPage;

