import { Carousel, Container } from "react-bootstrap";

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const TeamCarousel = () => {
  const carouselRef = React.useRef(null);
  const handleClick = () => {
    carouselRef.current.next();
  };

  return (
    <Carousel
      className="ref-carousel mb-5 mt-3 w-auto"
      interval={10000}
      fade={true}
      ref={carouselRef}
      onClick={handleClick}
    >
      <Carousel.Item>
        <StaticImage
          src={"../../../assets/images/portraits/new_space12657-1.jpg"}
          alt="Michael Kopeinigg"
          title="Michael Kopeinigg"
          placeholder="blurred"
          quality={100}
          imgStyle={{ maxHeight: "700px" }}
        />
        <Container className="d-flex justify-content-end">
          <div className="home-shape-box">
            <p lang="de" style={{ hyphens: "auto" }}>
              „Wir bauen, damit sich Menschen begegnen können und Gemeinschaft
              finden. Dafür stehen wir.“
              <br />- Michael Kopeinigg
            </p>
          </div>
        </Container>
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src={"../../../assets/images/portraits/new_space12645-1.jpg"}
          alt="Michael Kopeinigg"
          title="Michael Kopeinigg"
          placeholder="blurred"
          quality={100}
          imgStyle={{ maxHeight: "700px" }}
        />
        <Container className="d-flex justify-content-end">
          <div className="home-shape-box">
            <p>
              „Bei mir zählt der Handschlag.“
              <br />- Michael Kopeinigg
            </p>
          </div>
        </Container>
      </Carousel.Item>
    </Carousel>
  );
};
export default TeamCarousel;
